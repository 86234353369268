import React, { useEffect, useMemo, type ReactNode } from 'react';
import { render } from 'react-dom';
import '@atlaskit/css-reset';
import { Text } from '@atlaskit/primitives';
// eslint-disable-next-line jira/wrm/no-load-bridge
import { loadBridgeWeak } from '@atlassian/jira-common-bridge/src';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { standardizeLocale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl, FormattedMessage } from '@atlassian/jira-intl';
import { IntlProvider } from '@atlassian/jira-intl/src/intl-provider.tsx';
import { useJiraServiceManagementProjectNavigation } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/common/project-sidebar-navigation-resource/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { getNextgenSettingsIssueTypesUrl } from '@atlassian/jira-project-settings-apps-common/src/urls.tsx';
import RedirectIfProjectArchived from '@atlassian/jira-redirect-archived-project/src/ui/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { useServiceDeskAnalyticsContext } from '@atlassian/jira-router-resources-service-desk-analytics-context/src/services/index.tsx';
import { uifBoardResource } from '@atlassian/jira-router-resources-uif-board/src/index.tsx';
import { APP_NAMES } from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import ServiceDeskAppBase from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/app-base/index.tsx';
import type { ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { NextGenBoardSkeleton } from '@atlassian/jira-skeletons/src/ui/next-gen-board/NextGenBoardSkeleton.tsx';
import { SuspenseTrackerFallback } from '@atlassian/jira-software-suspense-tracker/src/index.tsx';
import type { UIFBoardCachedDataResult } from '@atlassian/jira-software-uif-early-script/src/index.tsx';
import RapidBoard from '@atlassian/jira-spa-apps-rapid-board/src/index.tsx';
import { MarkProductStart } from '@atlassian/jira-spa-performance-breakdown/src/utils/mark-product-start/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { useResource, usePathParam, useRouterActions } from '@atlassian/react-resource-router';
import { BackToBoard } from './back-to-board/index.tsx';
import { WORKFLOWS_LINK_TEST_ID } from './constants.tsx';
import messages from './messages.tsx';
import type { ServiceDeskBoardConfigBridgeApi } from './types.tsx';

const ColumnsConfigMappingPageDescription = ({
	projectKey,
	isSimplified,
}: {
	projectKey: ProjectKey;
	isSimplified: boolean;
}) => {
	const { message, url } = useMemo(
		() =>
			isSimplified
				? {
						message: messages.columnConfigDescriptionTmp,
						url: getNextgenSettingsIssueTypesUrl(SERVICE_DESK_PROJECT, projectKey),
					}
				: {
						message: messages.columnConfigDescriptionCmp,
						url: `/plugins/servlet/project-config/${projectKey}/workflows`,
					},
		[isSimplified, projectKey],
	);

	return (
		<FormattedMessage
			{...message}
			values={{
				// @ts-expect-error - Type '(content: string) => JSX.Element' is not assignable to type 'ReactNode'.
				a: (content: string) => (
					<a data-testid={WORKFLOWS_LINK_TEST_ID} href={url}>
						{content}
					</a>
				),
			}}
		/>
	);
};

const ColumnConstraintGroupInstructions = () => {
	const { formatMessage } = useIntl();
	return <Text>{formatMessage(messages.columnConstraintGroupInstructions)}</Text>;
};

type ServiceDeskBoardConfigBridge = (api: ServiceDeskBoardConfigBridgeApi) => void;

const getBoardSettingsTabs = (isBoardEnabled: boolean, isCalendarEnabled: boolean) => {
	const dateFieldsTab = isCalendarEnabled ? ['dateFields'] : [];

	if (!isBoardEnabled) {
		return ['filter', ...dateFieldsTab];
	}

	return [
		'columns',
		'filter',
		'quickFilters',
		'swimlanes',
		'cardColors',
		'cardLayout',
		...dateFieldsTab,
	];
};

let apiConfig: {
	locale: string;
	projectKey: ProjectKey;
	isSimplified: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	analyticsContextAttributes: any;
	isBoardEnabled: boolean;
	isCalendarEnabled: boolean;
	navigateBackToBoard: () => void;
} = {
	locale: '',
	projectKey: '',
	isSimplified: false,
	analyticsContextAttributes: {},
	isBoardEnabled: false,
	isCalendarEnabled: false,
	navigateBackToBoard: () => undefined,
};

const createApi = (
	// TODO: Remove parameter in full when cleaning up jsm_board_settings_back_button feature gate
	options?: {
		locale: string;
		projectKey: ProjectKey;
		isSimplified: boolean;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		analyticsContextAttributes: any;
		isBoardEnabled: boolean;
		isCalendarEnabled: boolean;
	},
): ServiceDeskBoardConfigBridgeApi => {
	const {
		locale: localeInjected,
		projectKey: projectKeyInjected,
		isSimplified: isSimplifiedInjected,
		analyticsContextAttributes: analyticsContextAttributesInjected,
		isBoardEnabled: isBoardEnabledInjected,
		isCalendarEnabled: isCalendarEnabledInjected,
	} = options ?? {};

	const renderComponent = ({
		children,
		container,
	}: {
		children: ReactNode;
		container: HTMLElement | null;
	}) =>
		render(
			<IntlProvider locale={standardizeLocale(apiConfig?.locale ?? localeInjected ?? '')}>
				{children}
			</IntlProvider>,
			container,
		);

	return {
		renderConfigEditScreen: () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			const configNav = document.getElementById('ghx-config-nav');
			if (configNav) {
				const tabs = getBoardSettingsTabs(
					apiConfig?.isBoardEnabled ?? isBoardEnabledInjected ?? false,
					apiConfig?.isCalendarEnabled ?? isCalendarEnabledInjected ?? false,
				);
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
				for (const child of (configNav as any).children) {
					if (!tabs.includes(child.getAttribute('data-tabitem'))) {
						child.style.display = 'none';
					}
				}
			}

			const backToBoard =
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				fg('jsm_board_settings_back_button') && document.getElementById('back-to-board');
			if (backToBoard) {
				const parentElement = backToBoard.parentElement;
				if (parentElement) {
					// Remove old button from the DOM
					parentElement.removeChild(backToBoard);

					// Render new button
					renderComponent({
						children: (
							<BackToBoard
								onClick={() => {
									apiConfig?.navigateBackToBoard();
								}}
							/>
						),
						container: parentElement,
					});
				}
			}
		},
		renderColumnMapping: () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			const configColumnsDescription = document.querySelector<HTMLElement>(
				'#ghx-config-columns-description',
			);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			if (document.body && document.body.contains(configColumnsDescription)) {
				renderComponent({
					children: (
						<ColumnsConfigMappingPageDescription
							projectKey={apiConfig?.projectKey ?? projectKeyInjected ?? ''}
							isSimplified={apiConfig?.isSimplified ?? isSimplifiedInjected ?? false}
						/>
					),
					container: configColumnsDescription,
				});
			}

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			const columnsConfigForm = document.getElementById('ghx-config-columns-form');
			if (columnsConfigForm) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
				for (const child of (columnsConfigForm as any).children) {
					if (child.id !== 'ghx-config-columns-column-constraint-group') {
						child.style.display = 'none';
					}
				}
			}

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			const columnConstraintDescriptionContainer = document.querySelector<HTMLElement>(
				'#ghx-config-columns-column-constraint-group div.description',
			);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			if (document.body && document.body.contains(columnConstraintDescriptionContainer)) {
				renderComponent({
					children: <ColumnConstraintGroupInstructions />,
					container: columnConstraintDescriptionContainer,
				});
			}

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			const backlogColumn = document.querySelector<HTMLElement>(
				'#ghx-mapping-columns div.ghx-kanplan-backlog-column',
			);
			if (backlogColumn) {
				backlogColumn.style.display = 'none';
			}
		},
		getAnalyticContextAttributes: () =>
			apiConfig?.analyticsContextAttributes ?? analyticsContextAttributesInjected,
	};
};

const useApi = ({
	projectKey,
	isSimplified,
	analyticsContextAttributes,
}: {
	projectKey: ProjectKey;
	isSimplified: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	analyticsContextAttributes: any;
}) => {
	const { locale } = useIntl();

	const { data } = useJiraServiceManagementProjectNavigation();

	const isBoardEnabled = !!data?.boardInfo.enabled;

	const isCalendarEnabled = !!data?.calendarInfo.enabled;

	const { push } = fg('jsm_board_settings_back_button')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useRouterActions()
		: { push: () => undefined };
	const [paramBoardId] = usePathParam('boardId');

	const boardUrl = isBoardEnabled
		? `/jira/servicedesk/projects/${projectKey}/boards/${paramBoardId}`
		: `/jira/servicedesk/projects/${projectKey}/boards/${paramBoardId}/calendar`;

	apiConfig = {
		locale,
		projectKey,
		isSimplified,
		analyticsContextAttributes,
		isBoardEnabled,
		isCalendarEnabled,
		navigateBackToBoard: () => {
			push(boardUrl);
		},
	};

	return fg('jsm_board_settings_back_button')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useMemo(() => createApi(), [])
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useMemo(
				() =>
					createApi({
						locale,
						projectKey,
						isSimplified,
						analyticsContextAttributes,
						isBoardEnabled,
						isCalendarEnabled,
					}),
				[
					locale,
					projectKey,
					isSimplified,
					analyticsContextAttributes,
					isBoardEnabled,
					isCalendarEnabled,
				],
			);
};

const ServicedeskLegacyBoardSettingsApp = ({
	projectKey,
	isSimplified,
	analyticsContextAttributes,
}: {
	projectKey: ProjectKey;
	isSimplified: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	analyticsContextAttributes: any;
}) => {
	const api = useApi({ projectKey, isSimplified, analyticsContextAttributes });

	useEffect(() => {
		/**
		 * Ensure that the configuration page is loaded. Legacy app has an issue where
		 * instead config page is rendered, it renders rapidboard page.
		 * This happens if custumer navigates back (with browser button) to the board, and then
		 * navigates to the configuration page from the board.
		 */
		let ensureConfigLoadedTimeout: ReturnType<typeof setTimeout> | null = null;
		const ensureConfigLoaded = () => {
			ensureConfigLoadedTimeout = setTimeout(() => {
				ensureConfigLoadedTimeout != null && clearTimeout(ensureConfigLoadedTimeout);
				ensureConfigLoadedTimeout = null;

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				const operations = document.getElementById('ghx-operations');
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				const configNav = document.getElementById('ghx-config-nav');

				if (!configNav) {
					operations
						? // eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
							window.location.reload()
						: ensureConfigLoaded();
				}
			}, 1000);
		};

		// eslint-disable-next-line jira/wrm/no-load-bridge
		loadBridgeWeak<ServiceDeskBoardConfigBridge>({
			name: 'jira-servicedesk/boards/configuration/bridge',
		}).then((bridge: ServiceDeskBoardConfigBridge) => {
			bridge(api);
			fg('jsm_board_settings_back_button') && ensureConfigLoaded();
		});

		return () => {
			ensureConfigLoadedTimeout != null && clearTimeout(ensureConfigLoadedTimeout);
			ensureConfigLoadedTimeout = null;
		};
	}, [api]);

	return (
		<>
			<RapidBoard />
			<RedirectIfProjectArchived projectKey={projectKey} />
		</>
	);
};

export const ServicedeskLegacyBoardSettingsAppLayout = () => {
	const [paramProjectKey] = usePathParam('projectKey');
	const cmpBoardData = useResource<UIFBoardCachedDataResult | null>(uifBoardResource);
	const { key } = cmpBoardData.data?.result?.boardLocation ?? {};
	const projectKey = key ?? paramProjectKey ?? null;
	const projectContext = useResource(projectContextResource);
	const projectId = projectContext?.data?.projectId;
	const simplified = projectContext?.data?.simplified;
	const { data: serviceDeskAnalyticsAttributes } = useServiceDeskAnalyticsContext();

	const analyticsWithAdditionalAttributes = useMemo(
		() => ({
			...serviceDeskAnalyticsAttributes,
			projectId,
		}),
		[serviceDeskAnalyticsAttributes, projectId],
	);

	const analyticsContextAttributes = analyticsWithAdditionalAttributes;

	if (!projectKey || simplified == null) {
		return null;
	}

	return (
		<UFOSegment name="servicedesk-legacy-board-settings">
			<Placeholder
				fallback={
					<>
						<NextGenBoardSkeleton />
						<SuspenseTrackerFallback />
					</>
				}
				name="servicedesk-legacy-board-settings"
			>
				<MarkProductStart />
				<ServiceDeskAppBase appName={APP_NAMES.BOARD_SETTINGS}>
					<ErrorBoundary
						id="spa-apps-servicedesk.legacy-board-settings"
						packageName="spa-apps-servicedesk-legacy-board-settings"
					>
						<ServicedeskLegacyBoardSettingsApp
							projectKey={projectKey}
							isSimplified={simplified}
							analyticsContextAttributes={analyticsContextAttributes}
						/>
					</ErrorBoundary>
				</ServiceDeskAppBase>
			</Placeholder>
		</UFOSegment>
	);
};
