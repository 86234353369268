import React from 'react';
import PerformanceMark from '@atlassian/jira-common-performance/src/set-performance-mark.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy, LazyWait } from '@atlassian/react-loosely-lazy';
import { LOAD_START_ISSUE_VIEW_RAPIDBOARD_MARK_KEY } from './utils.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const IssueView = lazy(
	() => import(/* webpackChunkName: "async-rapid-board-issue-defer" */ './index'),
	{ ssr: false },
);

type Props = {
	shouldLoad: boolean;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ shouldLoad }: Props) => (
	<ErrorBoundary id="async.issue-view.bridge.defer" packageName="jiraClassicBoardAndBacklog">
		<LazyWait until={shouldLoad}>
			<Placeholder
				name="issue-view"
				fallback={<PerformanceMark metricKey={LOAD_START_ISSUE_VIEW_RAPIDBOARD_MARK_KEY} />}
			>
				<IssueView />
			</Placeholder>
		</LazyWait>
	</ErrorBoundary>
);
