import React from 'react';
import { stringify } from 'query-string';
import { useContainerContext } from '@atlassian/jira-providers-container-context/src/main.tsx';
import { ScreenRedirect } from '@atlassian/jira-screen-redirect/src/ui/ScreenRedirect.tsx';
import { useRouter } from '@atlassian/react-resource-router';

const SOFTWARE_TIMELINE_CLASSIC_REGEX = /\/jira\/software\/c\/projects\/(\w+)\/boards\/(\d+)/;

export const RoadmapSettingRedirect = () => {
	const [{ data, error }] = useContainerContext();
	const [
		{
			query: { config, ...params },
			location,
		},
	] = useRouter();
	const match = SOFTWARE_TIMELINE_CLASSIC_REGEX.exec(location.pathname);

	if (!data || error) {
		return null;
	}

	if (match && config === 'roadmapConfig') {
		let url = `${location.pathname}?config=timelineConfig`;

		const queryParams = stringify(params);
		url += queryParams ? `&${queryParams}` : '';

		return <ScreenRedirect to={url} persistSearchParams={false} />;
	}

	return null;
};
