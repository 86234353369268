import React from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy, LazyWait } from '@atlassian/react-loosely-lazy';

type Props = {
	shouldLoad: boolean;
};

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyRapidApps = lazy(
	() => import(/* webpackChunkName: "async-rapid-board-config-defer" */ './index'),
	{ ssr: false },
);

export const RapidBoardConfig = ({ shouldLoad }: Props) => (
	<ErrorBoundary id="async.rapidboard-config.bridge.defer" packageName="jira.insights">
		<LazyWait until={shouldLoad}>
			<Placeholder name="rapid-board-config" fallback={null}>
				<LazyRapidApps />
			</Placeholder>
		</LazyWait>
	</ErrorBoundary>
);
