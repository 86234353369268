import { metrics } from '@atlassian/browser-metrics';
import { INSIGHT_BUTTON_MARK_KEY } from '../../ui/rapidboard-app/insights-button/utils.tsx';

export const classicBoard = metrics.pageLoad({
	key: 'classic-board',
});

export const classicBacklog = metrics.pageLoad({
	key: 'classic-backlog',
});

export const classicReport = metrics.pageLoad({
	key: 'classic-report',
});

export const classicBoardInsightButton = metrics.interaction({
	key: INSIGHT_BUTTON_MARK_KEY,
});
